import ApiInstance from './ApiInstance.js'

export default {
  
  getCountries() {
    return ApiInstance({ requiresAuth: true }).get('/countries');
  },
  addNewCountry(Data) {
    return ApiInstance({ requiresAuth: true }).post('/countries', {countries: Data});
  },
  editCountry(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/countries/${id}`, {country: Data[0]});
  },
  deleveCountry(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/countries/${id}`);
  },

  getCities() {
    return ApiInstance({ requiresAuth: true }).get('/cities');
  },

  addNewCity(Data) {
    return ApiInstance({ requiresAuth: true }).post('/cities', {cities: Data});
  },
  editCity(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/cities/${id}`, Data[0]);
  },
  deleveCity(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/cities/${id}`);
  },

}
