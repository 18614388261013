<script>
import Multiselect from "vue-multiselect";
import LocationService from "@/MainServices/LocationService.js";
import IndexService from "@/MainServices/IndexService.js";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import { ArrowUpIcon, HeartIcon, EyeIcon, ShoppingCartIcon } from "vue-feather-icons";
import Skeleton from "@/view/front/components/skeleton";
import Course from "@/view/front/components/course";

export default {
  components: { Multiselect, Navbar, Switcher, Footer, ArrowUpIcon, Skeleton, Course },
  data() {
    return {
      search_text: "",
      selected_cities: [],
      cities: [],
      selected_universites: [],
      universites: [],
      courses: [],
      loading: true,
      page_num: 1,
      show_load_more: true,
    };
  },
  methods: {
    search(page_num, load_more) {
      this.show_load_more = true;
      this.loading = true;
      let universities = [];
      this.selected_universites.map((el) => {
        universities.push(el.id);
      });
      let cities = [];
      this.selected_cities.map((el) => {
        cities.push(el.id);
      });
      IndexService.searchCourses(this.search_text, cities, universities, page_num).then(
        (res) => {
          this.loading = false;
          if (res.data.length < 17) {
            this.show_load_more = false;
          }
          if (load_more) {
            this.courses = [...this.courses, ...res.data];
          } else {
            this.courses = [];
            this.courses = res.data;
          }
        }
      );
    },
    loadmore() {
      this.page_num = this.page_num + 1;
      this.search(this.page_num, true);
    },
  },
  mounted() {
    //   document.getElementById("multiselectCities").value;
    //         console.log(document.getElementById("multiselectCities").value.length)

    // var multiselectCities = document.getElementById("multiselectCities")
    // var multiselectUniversties = document.getElementById("multiselectUniversties");

    // multiselectCities.addEventListener("keydown", function (e) {
    //     document.getElementById("multiselectCities").value = ' ';
    //   if (e.keyCode === 8 && e.target.value == ' ') {
    //     e.stopImmediatePropagation();
    //     e.preventDefault();
    //     return false;
    //   }
    // });
    // multiselectUniversties.addEventListener("keydown", function (e) {
    //   if (e.keyCode === 8 && e.target.value.length == 1) {
    //     e.stopImmediatePropagation();
    //     e.preventDefault();
    //     return false;
    //   }
    // });

    IndexService.getUniversitesWithListings().then((response) => {
      response.data.map((el) => {
        this.universites.push({ id: el.id, value: el.name });
      });
    });
    IndexService.getCities()
      .then((res) => {
        res.data.map((el) => {
          this.cities.push({
            id: el.id,
            value: el.city,
          });
        });
      })
      .catch((e) => {});
    this.search(1, false);
    this.loading = false;
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-custom bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Search Directory</h4>
              <div class="page-next"></div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

    <!-- Course list Start -->
    <section class="section" style="padding-bottom: 30px">
      <div class="container">
        <div class="row">
          <div class="col-3">
            <v-text-field
              v-model="search_text"
              color="purple name-2"
              label="Seach"
              required
              style="background-color: white; max-height: 47px"
            ></v-text-field>
          </div>
          <div class="col-3">
            <multiselect
              id="multiselectUniversties"
              v-model="selected_universites"
              :height="300"
              :options="universites"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select Universities"
              label="value"
              track-by="value"
              :preselect-first="false"
            >
            </multiselect>
          </div>
          <div class="col-3">
            <multiselect
              id="multiselectCities"
              v-model="selected_cities"
              :height="300"
              :options="cities"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select Cities"
              label="value"
              track-by="value"
              :preselect-first="false"
            >
            </multiselect>
          </div>
          <div class="col-2">
            <button class="btn btn-primary mt-1" @click="search(1)">Search</button>
          </div>
          <!--end col-->
          <!--end col-->
        </div>
        <div class="row align-items-center">
          <div
            class="col-lg-2 col-md-3 col-12 mt-4 pt-2"
            v-for="(singleCourse, index) in courses"
            :key="index"
          >
            <Course :course="singleCourse" />
          </div>
          <!--end col-->
        </div>
        <Skeleton v-if="loading" />
        <div class="col-md-12 mt-10" style="text-align: center">
          <button
            type="button"
            @click="loadmore"
            class="btn btn-primary"
            v-if="show_load_more"
          >
            Load More Courses
          </button>
          <p v-if="!show_load_more"><b>Search Result End</b></p>
        </div>

        <!--end row-->
      </div>

      <!--end container-->
    </section>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
